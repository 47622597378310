import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GENDERS, NON_CONFORMING_TITLES } from '@constants';
import { User } from '@models/user.model';
import { feedbackTypes } from '@modules/shared/dialogs/feedback-dialog/feedback-dialog.component';
import { AlertService } from '@services/alert.service';
import { AuthService } from '@services/auth.service';
import { ConfigLocaleService, ConfigRegex } from '@services/configLocale.service';
import { FeedbackService } from '@services/feedback.service';
import { UserService } from '@services/user.service';
import { EMPTY, Observable } from 'rxjs';
import { filter, switchMap, take, tap } from 'rxjs/operators';

@Component({
  selector: 'gc-complete-details',
  templateUrl: './complete-details.component.html',
  styleUrls: ['./complete-details.component.scss'],
})
export class CompleteDetailsComponent implements OnInit {
  profile$: Observable<User>;
  profileForm: FormGroup;
  maxDate = new Date();
  acceptTerms = false;
  idHidden = false;
  idRequired = false;

  constructor(
    private readonly alertService: AlertService,
    private readonly authService: AuthService,
    private readonly userService: UserService,
    private readonly formBuilder: FormBuilder,
    private readonly feedbackService: FeedbackService,
    private readonly router: Router,
    private readonly configService: ConfigLocaleService,
  ) {}

  get genderOptions() {
    return GENDERS;
  }

  ngOnInit() {
    this.profileForm = this.formBuilder.group({
      name: ['', Validators.required],
      surname: ['', Validators.required],
      idnumber: [''],
      dob: [''],
      gender: ['Other'],
      cellnr: [''],
    });

    // If id_hidden is set to true, hide the id number field and remove the validators, else, get the validators from the config service
    this.configService
      .getValueByKey$(['id_hidden'])
      .pipe(
        switchMap((config) => {
          this.idHidden = config.id_hidden === 'true';
          if (this.idHidden) {
            this.profileForm.get('idnumber').clearValidators();
            this.profileForm.get('idnumber').updateValueAndValidity();
            return EMPTY;
          } else {
            return this.configService.getValueByKey$(['id_number_validators_json']);
          }
        }),
        switchMap((config) => {
          const idNumberValidators = JSON.parse(config.id_number_validators_json) as ConfigRegex;
          this.profileForm.get('idnumber').addValidators(Validators.pattern(new RegExp(idNumberValidators.pattern)));
          if (idNumberValidators.required) {
            this.idRequired = true;
            this.profileForm.get('idnumber').addValidators(Validators.required);
          }
          return EMPTY;
        }),
      )
      .subscribe();

    // cellnr validators
    this.configService.getValueByKey$(['cell_validators_json']).subscribe((config) => {
      const cellValidators = JSON.parse(config.cell_validators_json) as ConfigRegex;
      this.profileForm.get('cellnr').addValidators(Validators.pattern(new RegExp(cellValidators.pattern)));
      if (cellValidators.required) {
        this.profileForm.get('cellnr').addValidators(Validators.required);
      }
    });

    this.profile$ = this.userService.profile$;
    this.profile$.pipe(take(2)).subscribe((profile) => this.profileForm.patchValue(profile));
  }

  populateDobAndGender() {
    if (!this.profileForm.get('idnumber').valid) {
      return;
    }
    const { dob, gender } = this.userService.extractDobAndGender(this.profileForm.value.idnumber);
    if (this.profileForm.value.dob !== dob) {
      this.profileForm.get('dob').setValue(dob);
    }
    if (this.profileForm.value.gender !== gender && !NON_CONFORMING_TITLES.includes(this.profileForm.value.title)) {
      this.profileForm.get('gender').setValue(gender);
    }
  }

  submit() {
    // eslint-disable-next-line prefer-const
    let { idnumber, cellnr, name, surname, dob, gender } = this.profileForm.value;
    const initials = this.userService.extractInitials(name);
    const termsAccepted = this.acceptTerms;
    // TODO: We need to have moment everywhere
    if (dob instanceof Object && dob.isValid()) {
      dob = dob.toISOString().substring(0, 10);
    }
    this.userService
      .updateUserProfile({
        cellnr,
        name,
        surname,
        idnumber,
        dob,
        gender,
        initials,
        terms_accepted: termsAccepted,
      })
      .subscribe(
        (profile) => {
          if (profile) {
            this.router.navigate(['/']);
          }
        },
        (err: unknown) => {
          this.alertService.handleErrorDialog$(err, 'Unable to update details');
        },
      );
  }

  logout() {
    this.alertService
      .confirmDialog$('Logout', { detail: 'Are you sure you wish to logout? You can complete this step at a later stage' })
      .pipe(
        filter((result) => !!result),
        tap(() => {
          this.authService
            .logout()
            .pipe(tap(async () => this.router.navigate(['/landing'])))
            .subscribe();
        }),
      )
      .subscribe();
  }

  feedback() {
    this.feedbackService.feedbackDialog(feedbackTypes.login).subscribe();
  }
}
