<gc-doctor-card #doctorCard [doctor]="doctor"></gc-doctor-card>
<div>
  <mat-divider class="divider"></mat-divider>
  <span class="center">
    <span class="overline">
      {{ this.shortestBookingType?.earliestTimeslotDay && (this.shortestBookingType.earliestTimeslotDay.date | todayTomorrow) + "'s" }}
      Availability
    </span>
    <mat-icon
      *ngIf="showTimezoneWarning"
      class="icon"
      matTooltip="Timezones differ from practioner's"
      matTooltipPosition="above"
      matTooltipClass="tooltip-warning"
      svgIcon="alert-circle-outline"
      (click)="tooltip.toggle()"
      #tooltip="matTooltip"
    ></mat-icon>
  </span>
  <div *ngIf="this.doctor.bookingTypes !== null; else noConnection">
    <!-- Null means it has errored -->
    <div class="button-container" fxLayout="row" fxLayoutAlign="space-between center">
      <div *ngIf="shortestBookingType; else loader">
        <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="8px" *ngIf="timeslots.length; noTimeslots">
          <div *ngFor="let timeslot of timeslots">
            <button
              class="timeslot-button"
              mat-raised-button
              color="primary"
              (click)="doctorCard.makeBooking(doctor.uuid, shortestBookingType.uuid, timeslot)"
            >
              {{ timeslot }}
            </button>
          </div>
        </div>
      </div>
      <ng-template #loader>
        <mat-progress-bar *ngIf="loadingTimeslots; else noTimeslots" class="progress-bar" mode="indeterminate"></mat-progress-bar>
        <button
          mat-stroked-button
          color="primary"
          type="button"
          class="booking-button"
          *ngIf="doctor.uuid"
          (click)="doctorCard.makeBooking(doctor.uuid)"
        >
          {{ bookingButtonTitle }}
        </button>
      </ng-template>
      <button
        mat-stroked-button
        color="primary"
        type="button"
        class="booking-button"
        *ngIf="doctor.uuid && timeslots.length"
        (click)="doctorCard.makeBooking(doctor.uuid)"
      >
        {{ bookingButtonTitle }}
      </button>
    </div>
  </div>
  <ng-template #noConnection>
    <div fxLayout="row" fxLayoutAlign="space-between">
      <div fxLayout="row" fxLayoutAlign="start center" class="no-timeslots error" fxLayoutGap="5px">
        <mat-icon svgIcon="alert"></mat-icon>
        <span>Connection issue to Partner</span>
      </div>
      <button mat-raised-button color="accent" (click)="getTimeslots()">Retry</button>
    </div>
  </ng-template>
</div>

<ng-template #noTimeslots>
  <div fxLayout="row" fxLayoutAlign="start center" class="no-timeslots" fxLayoutGap="5px">
    <mat-icon svgIcon="clock"></mat-icon>
    <span>{{ !!this.doctor.uuid ? 'No availability' : 'Doctor not setup for Online Bookings' }}</span>
  </div>
</ng-template>
