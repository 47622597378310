<div class="booking-display-card" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
  <gc-profile-picture [imgSrc]="booking.user.profilePicture" [fullName]="booking.user.fullName"></gc-profile-picture>
  <div fxFlex="75" fxLayout="column" fxLayoutAlign="space-between start" fxLayoutGap="4px">
    <span class="overline">
      {{ booking.date.toDate() | localizedDate : 'shortTime' }} on {{ booking.date.toDate() | localizedDate : 'fullDate' }} ({{
        booking.date.toDate() | localizedDate : 'Z'
      }})
    </span>
    <div
      fxFill
      class="info"
      fxLayout.gt-sm="row"
      fxLayoutAlign.gt-sm="start end"
      fxLayoutGap.gt-sm="16px"
      fxLayout.lt-md="column"
      fxLayoutAlign.lt-md="start"
    >
      <div class="names" fxLayout="column" fxLayoutGap="5px">
        <span class="patient-name">{{ booking.user.fullName }}</span>
        <span class="doctor-name" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
          <div class="booking-type-icon">
            <mat-icon [svgIcon]="displayIcon"></mat-icon>
          </div>
          <span>
            {{ booking.doctor.fullName }}
          </span>
          <a
            *ngIf="booking.doctor.landlines?.length"
            mat-flat-button
            matTooltip="Tel: {{ booking.doctor.landlines[0] }}"
            href="tel:{{ booking.doctor.landlines[0] }}"
            class="booking-display-card-button"
            target="_blank"
          >
            <mat-icon>phone</mat-icon>
          </a>
          <a
            *ngIf="booking.doctor.physicalAddress"
            mat-flat-button
            matTooltip="View on map"
            [href]="booking.doctor.googleMapsLink"
            class="booking-display-card-button"
            target="_blank"
          >
            <mat-icon>location_on</mat-icon>
          </a>
          <a
            *ngIf="booking.doctor.emailAddress"
            mat-flat-button
            matTooltip="Email"
            href="mailto:{{ booking.doctor.emailAddress }}"
            class="booking-display-card-button"
            target="_blank"
          >
            <mat-icon>email</mat-icon>
          </a>
        </span>
      </div>
    </div>
  </div>
</div>
