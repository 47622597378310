// Converts a timezone in form '+HH:MM' or '-HH:MM' to minutes
export function timezoneOffsetConversion(offsetStr: string): number {
  const sign = offsetStr.startsWith('+') ? 1 : -1;
  const [hoursStr, minutesStr] = offsetStr.slice(1).split(':'); // Remove sign and split

  const hours = parseInt(hoursStr, 10) || 0;
  const minutes = parseInt(minutesStr, 10) || 0;

  const totalMinutes = sign * (hours * 60 + minutes);
  return totalMinutes;
}

export interface Timezone {
  country: string;
  city: string;
  abbr: string;
  offset: number;
  isdst: boolean; // is this Daylight Saving Time
  text: string;
}

export interface TimezoneGroup {
  country: string;
  timezones: Timezone[];
}

export function getDeviceTimezone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export const TIMEZONES: TimezoneGroup[] = [
  {
    country: 'America',
    timezones: [
      {
        country: 'America',
        city: 'Anchorage',
        abbr: 'AKDT',
        offset: -8,
        isdst: true,
        text: '(UTC -09:00)',
      },
      {
        country: 'America',
        city: 'Juneau',
        abbr: 'AKDT',
        offset: -8,
        isdst: true,
        text: '(UTC -09:00)',
      },
      {
        country: 'America',
        city: 'Nome',
        abbr: 'AKDT',
        offset: -8,
        isdst: true,
        text: '(UTC -09:00)',
      },
      {
        country: 'America',
        city: 'Sitka',
        abbr: 'AKDT',
        offset: -8,
        isdst: true,
        text: '(UTC -09:00)',
      },
      {
        country: 'America',
        city: 'Yakutat',
        abbr: 'AKDT',
        offset: -8,
        isdst: true,
        text: '(UTC -09:00)',
      },
      {
        country: 'America',
        city: 'Santa_Isabel',
        abbr: 'PDT',
        offset: -7,
        isdst: true,
        text: '(UTC -08:00)',
      },
      {
        country: 'America',
        city: 'Los_Angeles',
        abbr: 'PDT',
        offset: -7,
        isdst: true,
        text: '(UTC -07:00)',
      },
      {
        country: 'America',
        city: 'Tijuana',
        abbr: 'PDT',
        offset: -7,
        isdst: true,
        text: '(UTC -07:00)',
      },
      {
        country: 'America',
        city: 'Vancouver',
        abbr: 'PDT',
        offset: -7,
        isdst: true,
        text: '(UTC -07:00)',
      },
      {
        country: 'America',
        city: 'Los_Angeles',
        abbr: 'PST',
        offset: -8,
        isdst: false,
        text: '(UTC -08:00)',
      },
      {
        country: 'America',
        city: 'Tijuana',
        abbr: 'PST',
        offset: -8,
        isdst: false,
        text: '(UTC -08:00)',
      },
      {
        country: 'America',
        city: 'Vancouver',
        abbr: 'PST',
        offset: -8,
        isdst: false,
        text: '(UTC -08:00)',
      },
      {
        country: 'America',
        city: 'Creston',
        abbr: 'UMST',
        offset: -7,
        isdst: false,
        text: '(UTC -07:00)',
      },
      {
        country: 'America',
        city: 'Dawson',
        abbr: 'UMST',
        offset: -7,
        isdst: false,
        text: '(UTC -07:00)',
      },
      {
        country: 'America',
        city: 'Dawson_Creek',
        abbr: 'UMST',
        offset: -7,
        isdst: false,
        text: '(UTC -07:00)',
      },
      {
        country: 'America',
        city: 'Hermosillo',
        abbr: 'UMST',
        offset: -7,
        isdst: false,
        text: '(UTC -07:00)',
      },
      {
        country: 'America',
        city: 'Phoenix',
        abbr: 'UMST',
        offset: -7,
        isdst: false,
        text: '(UTC -07:00)',
      },
      {
        country: 'America',
        city: 'Whitehorse',
        abbr: 'UMST',
        offset: -7,
        isdst: false,
        text: '(UTC -07:00)',
      },
      {
        country: 'America',
        city: 'Chihuahua',
        abbr: 'MDT',
        offset: -6,
        isdst: true,
        text: '(UTC -07:00)',
      },
      {
        country: 'America',
        city: 'Mazatlan',
        abbr: 'MDT',
        offset: -6,
        isdst: true,
        text: '(UTC -07:00)',
      },
      {
        country: 'America',
        city: 'Boise',
        abbr: 'MDT',
        offset: -6,
        isdst: true,
        text: '(UTC -07:00)',
      },
      {
        country: 'America',
        city: 'Cambridge_Bay',
        abbr: 'MDT',
        offset: -6,
        isdst: true,
        text: '(UTC -07:00)',
      },
      {
        country: 'America',
        city: 'Denver',
        abbr: 'MDT',
        offset: -6,
        isdst: true,
        text: '(UTC -07:00)',
      },
      {
        country: 'America',
        city: 'Edmonton',
        abbr: 'MDT',
        offset: -6,
        isdst: true,
        text: '(UTC -07:00)',
      },
      {
        country: 'America',
        city: 'Inuvik',
        abbr: 'MDT',
        offset: -6,
        isdst: true,
        text: '(UTC -07:00)',
      },
      {
        country: 'America',
        city: 'Ojinaga',
        abbr: 'MDT',
        offset: -6,
        isdst: true,
        text: '(UTC -07:00)',
      },
      {
        country: 'America',
        city: 'Yellowknife',
        abbr: 'MDT',
        offset: -6,
        isdst: true,
        text: '(UTC -07:00)',
      },
      {
        country: 'America',
        city: 'Belize',
        abbr: 'CAST',
        offset: -6,
        isdst: false,
        text: '(UTC -06:00)',
      },
      {
        country: 'America',
        city: 'Costa_Rica',
        abbr: 'CAST',
        offset: -6,
        isdst: false,
        text: '(UTC -06:00)',
      },
      {
        country: 'America',
        city: 'El_Salvador',
        abbr: 'CAST',
        offset: -6,
        isdst: false,
        text: '(UTC -06:00)',
      },
      {
        country: 'America',
        city: 'Guatemala',
        abbr: 'CAST',
        offset: -6,
        isdst: false,
        text: '(UTC -06:00)',
      },
      {
        country: 'America',
        city: 'Managua',
        abbr: 'CAST',
        offset: -6,
        isdst: false,
        text: '(UTC -06:00)',
      },
      {
        country: 'America',
        city: 'Tegucigalpa',
        abbr: 'CAST',
        offset: -6,
        isdst: false,
        text: '(UTC -06:00)',
      },
      {
        country: 'America',
        city: 'Chicago',
        abbr: 'CDT',
        offset: -5,
        isdst: true,
        text: '(UTC -06:00)',
      },
      {
        country: 'America',
        city: 'Indiana',
        abbr: 'CDT',
        offset: -5,
        isdst: true,
        text: '(UTC -06:00)',
      },
      {
        country: 'America',
        city: 'Indiana',
        abbr: 'CDT',
        offset: -5,
        isdst: true,
        text: '(UTC -06:00)',
      },
      {
        country: 'America',
        city: 'Matamoros',
        abbr: 'CDT',
        offset: -5,
        isdst: true,
        text: '(UTC -06:00)',
      },
      {
        country: 'America',
        city: 'Menominee',
        abbr: 'CDT',
        offset: -5,
        isdst: true,
        text: '(UTC -06:00)',
      },
      {
        country: 'America',
        city: 'North_Dakota',
        abbr: 'CDT',
        offset: -5,
        isdst: true,
        text: '(UTC -06:00)',
      },
      {
        country: 'America',
        city: 'North_Dakota',
        abbr: 'CDT',
        offset: -5,
        isdst: true,
        text: '(UTC -06:00)',
      },
      {
        country: 'America',
        city: 'North_Dakota',
        abbr: 'CDT',
        offset: -5,
        isdst: true,
        text: '(UTC -06:00)',
      },
      {
        country: 'America',
        city: 'Rainy_River',
        abbr: 'CDT',
        offset: -5,
        isdst: true,
        text: '(UTC -06:00)',
      },
      {
        country: 'America',
        city: 'Rankin_Inlet',
        abbr: 'CDT',
        offset: -5,
        isdst: true,
        text: '(UTC -06:00)',
      },
      {
        country: 'America',
        city: 'Resolute',
        abbr: 'CDT',
        offset: -5,
        isdst: true,
        text: '(UTC -06:00)',
      },
      {
        country: 'America',
        city: 'Winnipeg',
        abbr: 'CDT',
        offset: -5,
        isdst: true,
        text: '(UTC -06:00)',
      },
      {
        country: 'America',
        city: 'Bahia_Banderas',
        abbr: 'CDT',
        offset: -5,
        isdst: true,
        text: '(UTC -06:00)',
      },
      {
        country: 'America',
        city: 'Cancun',
        abbr: 'CDT',
        offset: -5,
        isdst: true,
        text: '(UTC -06:00)',
      },
      {
        country: 'America',
        city: 'Merida',
        abbr: 'CDT',
        offset: -5,
        isdst: true,
        text: '(UTC -06:00)',
      },
      {
        country: 'America',
        city: 'Mexico_City',
        abbr: 'CDT',
        offset: -5,
        isdst: true,
        text: '(UTC -06:00)',
      },
      {
        country: 'America',
        city: 'Monterrey',
        abbr: 'CDT',
        offset: -5,
        isdst: true,
        text: '(UTC -06:00)',
      },
      {
        country: 'America',
        city: 'Regina',
        abbr: 'CCST',
        offset: -6,
        isdst: false,
        text: '(UTC -06:00)',
      },
      {
        country: 'America',
        city: 'Swift_Current',
        abbr: 'CCST',
        offset: -6,
        isdst: false,
        text: '(UTC -06:00)',
      },
      {
        country: 'America',
        city: 'Bogota',
        abbr: 'SPST',
        offset: -5,
        isdst: false,
        text: '(UTC -05:00)',
      },
      {
        country: 'America',
        city: 'Cayman',
        abbr: 'SPST',
        offset: -5,
        isdst: false,
        text: '(UTC -05:00)',
      },
      {
        country: 'America',
        city: 'Coral_Harbour',
        abbr: 'SPST',
        offset: -5,
        isdst: false,
        text: '(UTC -05:00)',
      },
      {
        country: 'America',
        city: 'Eirunepe',
        abbr: 'SPST',
        offset: -5,
        isdst: false,
        text: '(UTC -05:00)',
      },
      {
        country: 'America',
        city: 'Guayaquil',
        abbr: 'SPST',
        offset: -5,
        isdst: false,
        text: '(UTC -05:00)',
      },
      {
        country: 'America',
        city: 'Jamaica',
        abbr: 'SPST',
        offset: -5,
        isdst: false,
        text: '(UTC -05:00)',
      },
      {
        country: 'America',
        city: 'Lima',
        abbr: 'SPST',
        offset: -5,
        isdst: false,
        text: '(UTC -05:00)',
      },
      {
        country: 'America',
        city: 'Panama',
        abbr: 'SPST',
        offset: -5,
        isdst: false,
        text: '(UTC -05:00)',
      },
      {
        country: 'America',
        city: 'Rio_Branco',
        abbr: 'SPST',
        offset: -5,
        isdst: false,
        text: '(UTC -05:00)',
      },
      {
        country: 'America',
        city: 'Detroit',
        abbr: 'EST',
        offset: -5,
        isdst: false,
        text: '(UTC -05:00)',
      },
      {
        country: 'America',
        city: 'Havana',
        abbr: 'EST',
        offset: -5,
        isdst: false,
        text: '(UTC -05:00)',
      },
      {
        country: 'America',
        city: 'Indiana',
        abbr: 'EST',
        offset: -5,
        isdst: false,
        text: '(UTC -05:00)',
      },
      {
        country: 'America',
        city: 'Indiana',
        abbr: 'EST',
        offset: -5,
        isdst: false,
        text: '(UTC -05:00)',
      },
      {
        country: 'America',
        city: 'Indiana',
        abbr: 'EST',
        offset: -5,
        isdst: false,
        text: '(UTC -05:00)',
      },
      {
        country: 'America',
        city: 'Iqaluit',
        abbr: 'EST',
        offset: -5,
        isdst: false,
        text: '(UTC -05:00)',
      },
      {
        country: 'America',
        city: 'Kentucky',
        abbr: 'EST',
        offset: -5,
        isdst: false,
        text: '(UTC -05:00)',
      },
      {
        country: 'America',
        city: 'Louisville',
        abbr: 'EST',
        offset: -5,
        isdst: false,
        text: '(UTC -05:00)',
      },
      {
        country: 'America',
        city: 'Montreal',
        abbr: 'EST',
        offset: -5,
        isdst: false,
        text: '(UTC -05:00)',
      },
      {
        country: 'America',
        city: 'Nassau',
        abbr: 'EST',
        offset: -5,
        isdst: false,
        text: '(UTC -05:00)',
      },
      {
        country: 'America',
        city: 'New_York',
        abbr: 'EST',
        offset: -5,
        isdst: false,
        text: '(UTC -05:00)',
      },
      {
        country: 'America',
        city: 'Nipigon',
        abbr: 'EST',
        offset: -5,
        isdst: false,
        text: '(UTC -05:00)',
      },
      {
        country: 'America',
        city: 'Pangnirtung',
        abbr: 'EST',
        offset: -5,
        isdst: false,
        text: '(UTC -05:00)',
      },
      {
        country: 'America',
        city: 'Port -au -Prince',
        abbr: 'EST',
        offset: -5,
        isdst: false,
        text: '(UTC -05:00)',
      },
      {
        country: 'America',
        city: 'Thunder_Bay',
        abbr: 'EST',
        offset: -5,
        isdst: false,
        text: '(UTC -05:00)',
      },
      {
        country: 'America',
        city: 'Toronto',
        abbr: 'EST',
        offset: -5,
        isdst: false,
        text: '(UTC -05:00)',
      },
      {
        country: 'America',
        city: 'Detroit',
        abbr: 'EDT',
        offset: -4,
        isdst: true,
        text: '(UTC -04:00)',
      },
      {
        country: 'America',
        city: 'Havana',
        abbr: 'EDT',
        offset: -4,
        isdst: true,
        text: '(UTC -04:00)',
      },
      {
        country: 'America',
        city: 'Indiana',
        abbr: 'EDT',
        offset: -4,
        isdst: true,
        text: '(UTC -04:00)',
      },
      {
        country: 'America',
        city: 'Indiana',
        abbr: 'EDT',
        offset: -4,
        isdst: true,
        text: '(UTC -04:00)',
      },
      {
        country: 'America',
        city: 'Indiana',
        abbr: 'EDT',
        offset: -4,
        isdst: true,
        text: '(UTC -04:00)',
      },
      {
        country: 'America',
        city: 'Iqaluit',
        abbr: 'EDT',
        offset: -4,
        isdst: true,
        text: '(UTC -04:00)',
      },
      {
        country: 'America',
        city: 'Kentucky',
        abbr: 'EDT',
        offset: -4,
        isdst: true,
        text: '(UTC -04:00)',
      },
      {
        country: 'America',
        city: 'Louisville',
        abbr: 'EDT',
        offset: -4,
        isdst: true,
        text: '(UTC -04:00)',
      },
      {
        country: 'America',
        city: 'Montreal',
        abbr: 'EDT',
        offset: -4,
        isdst: true,
        text: '(UTC -04:00)',
      },
      {
        country: 'America',
        city: 'Nassau',
        abbr: 'EDT',
        offset: -4,
        isdst: true,
        text: '(UTC -04:00)',
      },
      {
        country: 'America',
        city: 'New_York',
        abbr: 'EDT',
        offset: -4,
        isdst: true,
        text: '(UTC -04:00)',
      },
      {
        country: 'America',
        city: 'Nipigon',
        abbr: 'EDT',
        offset: -4,
        isdst: true,
        text: '(UTC -04:00)',
      },
      {
        country: 'America',
        city: 'Pangnirtung',
        abbr: 'EDT',
        offset: -4,
        isdst: true,
        text: '(UTC -04:00)',
      },
      {
        country: 'America',
        city: 'Port -au -Prince',
        abbr: 'EDT',
        offset: -4,
        isdst: true,
        text: '(UTC -04:00)',
      },
      {
        country: 'America',
        city: 'Thunder_Bay',
        abbr: 'EDT',
        offset: -4,
        isdst: true,
        text: '(UTC -04:00)',
      },
      {
        country: 'America',
        city: 'Toronto',
        abbr: 'EDT',
        offset: -4,
        isdst: true,
        text: '(UTC -04:00)',
      },
      {
        country: 'America',
        city: 'Indiana',
        abbr: 'UEDT',
        offset: -5,
        isdst: false,
        text: '(UTC -05:00)',
      },
      {
        country: 'America',
        city: 'Indiana',
        abbr: 'UEDT',
        offset: -5,
        isdst: false,
        text: '(UTC -05:00)',
      },
      {
        country: 'America',
        city: 'Indianapolis',
        abbr: 'UEDT',
        offset: -5,
        isdst: false,
        text: '(UTC -05:00)',
      },
      {
        country: 'America',
        city: 'Caracas',
        abbr: 'VST',
        offset: -4.5,
        isdst: false,
        text: '(UTC -04:30)',
      },
      {
        country: 'America',
        city: 'Asuncion',
        abbr: 'PYT',
        offset: -4,
        isdst: false,
        text: '(UTC -04:00)',
      },
      {
        country: 'America',
        city: 'Glace_Bay',
        abbr: 'ADT',
        offset: -3,
        isdst: true,
        text: '(UTC -04:00)',
      },
      {
        country: 'America',
        city: 'Goose_Bay',
        abbr: 'ADT',
        offset: -3,
        isdst: true,
        text: '(UTC -04:00)',
      },
      {
        country: 'America',
        city: 'Halifax',
        abbr: 'ADT',
        offset: -3,
        isdst: true,
        text: '(UTC -04:00)',
      },
      {
        country: 'America',
        city: 'Moncton',
        abbr: 'ADT',
        offset: -3,
        isdst: true,
        text: '(UTC -04:00)',
      },
      {
        country: 'America',
        city: 'Thule',
        abbr: 'ADT',
        offset: -3,
        isdst: true,
        text: '(UTC -04:00)',
      },
      {
        country: 'America',
        city: 'Campo_Grande',
        abbr: 'CBST',
        offset: -4,
        isdst: false,
        text: '(UTC -04:00)',
      },
      {
        country: 'America',
        city: 'Cuiaba',
        abbr: 'CBST',
        offset: -4,
        isdst: false,
        text: '(UTC -04:00)',
      },
      {
        country: 'America',
        city: 'Anguilla',
        abbr: 'SWST',
        offset: -4,
        isdst: false,
        text: '(UTC -04:00)',
      },
      {
        country: 'America',
        city: 'Antigua',
        abbr: 'SWST',
        offset: -4,
        isdst: false,
        text: '(UTC -04:00)',
      },
      {
        country: 'America',
        city: 'Aruba',
        abbr: 'SWST',
        offset: -4,
        isdst: false,
        text: '(UTC -04:00)',
      },
      {
        country: 'America',
        city: 'Barbados',
        abbr: 'SWST',
        offset: -4,
        isdst: false,
        text: '(UTC -04:00)',
      },
      {
        country: 'America',
        city: 'Blanc -Sablon',
        abbr: 'SWST',
        offset: -4,
        isdst: false,
        text: '(UTC -04:00)',
      },
      {
        country: 'America',
        city: 'Boa_Vista',
        abbr: 'SWST',
        offset: -4,
        isdst: false,
        text: '(UTC -04:00)',
      },
      {
        country: 'America',
        city: 'Curacao',
        abbr: 'SWST',
        offset: -4,
        isdst: false,
        text: '(UTC -04:00)',
      },
      {
        country: 'America',
        city: 'Dominica',
        abbr: 'SWST',
        offset: -4,
        isdst: false,
        text: '(UTC -04:00)',
      },
      {
        country: 'America',
        city: 'Grand_Turk',
        abbr: 'SWST',
        offset: -4,
        isdst: false,
        text: '(UTC -04:00)',
      },
      {
        country: 'America',
        city: 'Grenada',
        abbr: 'SWST',
        offset: -4,
        isdst: false,
        text: '(UTC -04:00)',
      },
      {
        country: 'America',
        city: 'Guadeloupe',
        abbr: 'SWST',
        offset: -4,
        isdst: false,
        text: '(UTC -04:00)',
      },
      {
        country: 'America',
        city: 'Guyana',
        abbr: 'SWST',
        offset: -4,
        isdst: false,
        text: '(UTC -04:00)',
      },
      {
        country: 'America',
        city: 'Kralendijk',
        abbr: 'SWST',
        offset: -4,
        isdst: false,
        text: '(UTC -04:00)',
      },
      {
        country: 'America',
        city: 'La_Paz',
        abbr: 'SWST',
        offset: -4,
        isdst: false,
        text: '(UTC -04:00)',
      },
      {
        country: 'America',
        city: 'Lower_Princes',
        abbr: 'SWST',
        offset: -4,
        isdst: false,
        text: '(UTC -04:00)',
      },
      {
        country: 'America',
        city: 'Manaus',
        abbr: 'SWST',
        offset: -4,
        isdst: false,
        text: '(UTC -04:00)',
      },
      {
        country: 'America',
        city: 'Marigot',
        abbr: 'SWST',
        offset: -4,
        isdst: false,
        text: '(UTC -04:00)',
      },
      {
        country: 'America',
        city: 'Martinique',
        abbr: 'SWST',
        offset: -4,
        isdst: false,
        text: '(UTC -04:00)',
      },
      {
        country: 'America',
        city: 'Montserrat',
        abbr: 'SWST',
        offset: -4,
        isdst: false,
        text: '(UTC -04:00)',
      },
      {
        country: 'America',
        city: 'Port_of_Spain',
        abbr: 'SWST',
        offset: -4,
        isdst: false,
        text: '(UTC -04:00)',
      },
      {
        country: 'America',
        city: 'Porto_Velho',
        abbr: 'SWST',
        offset: -4,
        isdst: false,
        text: '(UTC -04:00)',
      },
      {
        country: 'America',
        city: 'Puerto_Rico',
        abbr: 'SWST',
        offset: -4,
        isdst: false,
        text: '(UTC -04:00)',
      },
      {
        country: 'America',
        city: 'Santo_Domingo',
        abbr: 'SWST',
        offset: -4,
        isdst: false,
        text: '(UTC -04:00)',
      },
      {
        country: 'America',
        city: 'St_Barthelemy',
        abbr: 'SWST',
        offset: -4,
        isdst: false,
        text: '(UTC -04:00)',
      },
      {
        country: 'America',
        city: 'St_Kitts',
        abbr: 'SWST',
        offset: -4,
        isdst: false,
        text: '(UTC -04:00)',
      },
      {
        country: 'America',
        city: 'St_Lucia',
        abbr: 'SWST',
        offset: -4,
        isdst: false,
        text: '(UTC -04:00)',
      },
      {
        country: 'America',
        city: 'St_Thomas',
        abbr: 'SWST',
        offset: -4,
        isdst: false,
        text: '(UTC -04:00)',
      },
      {
        country: 'America',
        city: 'St_Vincent',
        abbr: 'SWST',
        offset: -4,
        isdst: false,
        text: '(UTC -04:00)',
      },
      {
        country: 'America',
        city: 'Tortola',
        abbr: 'SWST',
        offset: -4,
        isdst: false,
        text: '(UTC -04:00)',
      },
      {
        country: 'America',
        city: 'Santiago',
        abbr: 'PSST',
        offset: -4,
        isdst: false,
        text: '(UTC -04:00)',
      },
      {
        country: 'America',
        city: 'St_Johns',
        abbr: 'NDT',
        offset: -2.5,
        isdst: true,
        text: '(UTC -03:30)',
      },
      {
        country: 'America',
        city: 'Sao_Paulo',
        abbr: 'ESAST',
        offset: -3,
        isdst: false,
        text: '(UTC -03:00)',
      },
      {
        country: 'America',
        city: 'Argentina',
        abbr: 'AST',
        offset: -3,
        isdst: false,
        text: '(UTC -03:00)',
      },
      {
        country: 'America',
        city: 'Argentina',
        abbr: 'AST',
        offset: -3,
        isdst: false,
        text: '(UTC -03:00)',
      },
      {
        country: 'America',
        city: 'Argentina',
        abbr: 'AST',
        offset: -3,
        isdst: false,
        text: '(UTC -03:00)',
      },
      {
        country: 'America',
        city: 'Argentina',
        abbr: 'AST',
        offset: -3,
        isdst: false,
        text: '(UTC -03:00)',
      },
      {
        country: 'America',
        city: 'Argentina',
        abbr: 'AST',
        offset: -3,
        isdst: false,
        text: '(UTC -03:00)',
      },
      {
        country: 'America',
        city: 'Argentina',
        abbr: 'AST',
        offset: -3,
        isdst: false,
        text: '(UTC -03:00)',
      },
      {
        country: 'America',
        city: 'Argentina',
        abbr: 'AST',
        offset: -3,
        isdst: false,
        text: '(UTC -03:00)',
      },
      {
        country: 'America',
        city: 'Argentina',
        abbr: 'AST',
        offset: -3,
        isdst: false,
        text: '(UTC -03:00)',
      },
      {
        country: 'America',
        city: 'Argentina',
        abbr: 'AST',
        offset: -3,
        isdst: false,
        text: '(UTC -03:00)',
      },
      {
        country: 'America',
        city: 'Argentina',
        abbr: 'AST',
        offset: -3,
        isdst: false,
        text: '(UTC -03:00)',
      },
      {
        country: 'America',
        city: 'Argentina',
        abbr: 'AST',
        offset: -3,
        isdst: false,
        text: '(UTC -03:00)',
      },
      {
        country: 'America',
        city: 'Argentina',
        abbr: 'AST',
        offset: -3,
        isdst: false,
        text: '(UTC -03:00)',
      },
      {
        country: 'America',
        city: 'Buenos_Aires',
        abbr: 'AST',
        offset: -3,
        isdst: false,
        text: '(UTC -03:00)',
      },
      {
        country: 'America',
        city: 'Catamarca',
        abbr: 'AST',
        offset: -3,
        isdst: false,
        text: '(UTC -03:00)',
      },
      {
        country: 'America',
        city: 'Cordoba',
        abbr: 'AST',
        offset: -3,
        isdst: false,
        text: '(UTC -03:00)',
      },
      {
        country: 'America',
        city: 'Jujuy',
        abbr: 'AST',
        offset: -3,
        isdst: false,
        text: '(UTC -03:00)',
      },
      {
        country: 'America',
        city: 'Mendoza',
        abbr: 'AST',
        offset: -3,
        isdst: false,
        text: '(UTC -03:00)',
      },
      {
        country: 'America',
        city: 'Araguaina',
        abbr: 'SEST',
        offset: -3,
        isdst: false,
        text: '(UTC -03:00)',
      },
      {
        country: 'America',
        city: 'Belem',
        abbr: 'SEST',
        offset: -3,
        isdst: false,
        text: '(UTC -03:00)',
      },
      {
        country: 'America',
        city: 'Cayenne',
        abbr: 'SEST',
        offset: -3,
        isdst: false,
        text: '(UTC -03:00)',
      },
      {
        country: 'America',
        city: 'Fortaleza',
        abbr: 'SEST',
        offset: -3,
        isdst: false,
        text: '(UTC -03:00)',
      },
      {
        country: 'America',
        city: 'Maceio',
        abbr: 'SEST',
        offset: -3,
        isdst: false,
        text: '(UTC -03:00)',
      },
      {
        country: 'America',
        city: 'Paramaribo',
        abbr: 'SEST',
        offset: -3,
        isdst: false,
        text: '(UTC -03:00)',
      },
      {
        country: 'America',
        city: 'Recife',
        abbr: 'SEST',
        offset: -3,
        isdst: false,
        text: '(UTC -03:00)',
      },
      {
        country: 'America',
        city: 'Santarem',
        abbr: 'SEST',
        offset: -3,
        isdst: false,
        text: '(UTC -03:00)',
      },
      {
        country: 'America',
        city: 'Godthab',
        abbr: 'GDT',
        offset: -3,
        isdst: true,
        text: '(UTC -03:00)',
      },
      {
        country: 'America',
        city: 'Montevideo',
        abbr: 'MST',
        offset: -3,
        isdst: false,
        text: '(UTC -03:00)',
      },
      {
        country: 'America',
        city: 'Bahia',
        abbr: 'BST',
        offset: -3,
        isdst: false,
        text: '(UTC -03:00)',
      },
      {
        country: 'America',
        city: 'Noronha',
        abbr: 'U',
        offset: -2,
        isdst: false,
        text: '(UTC -02:00)',
      },
      {
        country: 'America',
        city: 'Scoresbysund',
        abbr: 'ADT',
        offset: 0,
        isdst: true,
        text: '(UTC -01:00)',
      },
      {
        country: 'America',
        city: 'Danmarkshavn',
        abbr: 'UTC',
        offset: 0,
        isdst: false,
        text: '(UTC 00:00)',
      },
    ],
  },
  {
    country: 'Australia',
    timezones: [
      {
        country: 'Australia',
        city: 'Perth',
        abbr: 'WAST',
        offset: 8,
        isdst: false,
        text: '(UTC +08:00)',
      },
      {
        country: 'Australia',
        city: 'Adelaide',
        abbr: 'CAST',
        offset: 9.5,
        isdst: false,
        text: '(UTC +09:30)',
      },
      {
        country: 'Australia',
        city: 'Broken_Hill',
        abbr: 'CAST',
        offset: 9.5,
        isdst: false,
        text: '(UTC +09:30)',
      },
      {
        country: 'Australia',
        city: 'Darwin',
        abbr: 'ACST',
        offset: 9.5,
        isdst: false,
        text: '(UTC +09:30)',
      },
      {
        country: 'Australia',
        city: 'Brisbane',
        abbr: 'EAST',
        offset: 10,
        isdst: false,
        text: '(UTC +10:00)',
      },
      {
        country: 'Australia',
        city: 'Lindeman',
        abbr: 'EAST',
        offset: 10,
        isdst: false,
        text: '(UTC +10:00)',
      },
      {
        country: 'Australia',
        city: 'Melbourne',
        abbr: 'AEST',
        offset: 10,
        isdst: false,
        text: '(UTC +10:00)',
      },
      {
        country: 'Australia',
        city: 'Sydney',
        abbr: 'AEST',
        offset: 10,
        isdst: false,
        text: '(UTC +10:00)',
      },
      {
        country: 'Australia',
        city: 'Currie',
        abbr: 'TST',
        offset: 10,
        isdst: false,
        text: '(UTC +10:00)',
      },
      {
        country: 'Australia',
        city: 'Hobart',
        abbr: 'TST',
        offset: 10,
        isdst: false,
        text: '(UTC +10:00)',
      },
    ],
  },
  {
    country: 'MST7MDT',
    timezones: [
      {
        country: 'MST7MDT',
        city: 'undefined',
        abbr: 'MDT',
        offset: -6,
        isdst: true,
        text: '(UTC -07:00)',
      },
    ],
  },
  {
    country: 'Pacific',
    timezones: [
      {
        country: 'Pacific',
        city: 'Midway',
        abbr: 'U',
        offset: -11,
        isdst: false,
        text: '(UTC -11:00)',
      },
      {
        country: 'Pacific',
        city: 'Niue',
        abbr: 'U',
        offset: -11,
        isdst: false,
        text: '(UTC -11:00)',
      },
      {
        country: 'Pacific',
        city: 'Pago_Pago',
        abbr: 'U',
        offset: -11,
        isdst: false,
        text: '(UTC -11:00)',
      },
      {
        country: 'Pacific',
        city: 'Honolulu',
        abbr: 'HST',
        offset: -10,
        isdst: false,
        text: '(UTC -10:00)',
      },
      {
        country: 'Pacific',
        city: 'Johnston',
        abbr: 'HST',
        offset: -10,
        isdst: false,
        text: '(UTC -10:00)',
      },
      {
        country: 'Pacific',
        city: 'Rarotonga',
        abbr: 'HST',
        offset: -10,
        isdst: false,
        text: '(UTC -10:00)',
      },
      {
        country: 'Pacific',
        city: 'Tahiti',
        abbr: 'HST',
        offset: -10,
        isdst: false,
        text: '(UTC -10:00)',
      },
      {
        country: 'Pacific',
        city: 'Galapagos',
        abbr: 'CAST',
        offset: -6,
        isdst: false,
        text: '(UTC -06:00)',
      },
      {
        country: 'Pacific',
        city: 'Palau',
        abbr: 'JST',
        offset: 9,
        isdst: false,
        text: '(UTC +09:00)',
      },
      {
        country: 'Pacific',
        city: 'Guam',
        abbr: 'WPST',
        offset: 10,
        isdst: false,
        text: '(UTC +10:00)',
      },
      {
        country: 'Pacific',
        city: 'Port_Moresby',
        abbr: 'WPST',
        offset: 10,
        isdst: false,
        text: '(UTC +10:00)',
      },
      {
        country: 'Pacific',
        city: 'Saipan',
        abbr: 'WPST',
        offset: 10,
        isdst: false,
        text: '(UTC +10:00)',
      },
      {
        country: 'Pacific',
        city: 'Truk',
        abbr: 'WPST',
        offset: 10,
        isdst: false,
        text: '(UTC +10:00)',
      },
      {
        country: 'Pacific',
        city: 'Efate',
        abbr: 'CPST',
        offset: 11,
        isdst: false,
        text: '(UTC +11:00)',
      },
      {
        country: 'Pacific',
        city: 'Guadalcanal',
        abbr: 'CPST',
        offset: 11,
        isdst: false,
        text: '(UTC +11:00)',
      },
      {
        country: 'Pacific',
        city: 'Kosrae',
        abbr: 'CPST',
        offset: 11,
        isdst: false,
        text: '(UTC +11:00)',
      },
      {
        country: 'Pacific',
        city: 'Noumea',
        abbr: 'CPST',
        offset: 11,
        isdst: false,
        text: '(UTC +11:00)',
      },
      {
        country: 'Pacific',
        city: 'Ponape',
        abbr: 'CPST',
        offset: 11,
        isdst: false,
        text: '(UTC +11:00)',
      },
      {
        country: 'Pacific',
        city: 'Auckland',
        abbr: 'NZST',
        offset: 12,
        isdst: false,
        text: '(UTC +12:00)',
      },
      {
        country: 'Pacific',
        city: 'Funafuti',
        abbr: 'U',
        offset: 12,
        isdst: false,
        text: '(UTC +12:00)',
      },
      {
        country: 'Pacific',
        city: 'Kwajalein',
        abbr: 'U',
        offset: 12,
        isdst: false,
        text: '(UTC +12:00)',
      },
      {
        country: 'Pacific',
        city: 'Majuro',
        abbr: 'U',
        offset: 12,
        isdst: false,
        text: '(UTC +12:00)',
      },
      {
        country: 'Pacific',
        city: 'Nauru',
        abbr: 'U',
        offset: 12,
        isdst: false,
        text: '(UTC +12:00)',
      },
      {
        country: 'Pacific',
        city: 'Tarawa',
        abbr: 'U',
        offset: 12,
        isdst: false,
        text: '(UTC +12:00)',
      },
      {
        country: 'Pacific',
        city: 'Wake',
        abbr: 'U',
        offset: 12,
        isdst: false,
        text: '(UTC +12:00)',
      },
      {
        country: 'Pacific',
        city: 'Wallis',
        abbr: 'U',
        offset: 12,
        isdst: false,
        text: '(UTC +12:00)',
      },
      {
        country: 'Pacific',
        city: 'Fiji',
        abbr: 'FST',
        offset: 12,
        isdst: false,
        text: '(UTC +12:00)',
      },
      {
        country: 'Pacific',
        city: 'Enderbury',
        abbr: 'TST',
        offset: 13,
        isdst: false,
        text: '(UTC +13:00)',
      },
      {
        country: 'Pacific',
        city: 'Fakaofo',
        abbr: 'TST',
        offset: 13,
        isdst: false,
        text: '(UTC +13:00)',
      },
      {
        country: 'Pacific',
        city: 'Tongatapu',
        abbr: 'TST',
        offset: 13,
        isdst: false,
        text: '(UTC +13:00)',
      },
      {
        country: 'Pacific',
        city: 'Apia',
        abbr: 'SST',
        offset: 13,
        isdst: false,
        text: '(UTC +13:00)',
      },
    ],
  },
  {
    country: 'PST8PDT',
    timezones: [
      {
        country: 'PST8PDT',
        city: 'undefined',
        abbr: 'PST',
        offset: -8,
        isdst: false,
        text: '(UTC -08:00)',
      },
    ],
  },
  {
    country: 'CST6CDT',
    timezones: [
      {
        country: 'CST6CDT',
        city: 'undefined',
        abbr: 'CDT',
        offset: -5,
        isdst: true,
        text: '(UTC -06:00)',
      },
    ],
  },
  {
    country: 'Atlantic',
    timezones: [
      {
        country: 'Atlantic',
        city: 'Bermuda',
        abbr: 'ADT',
        offset: -3,
        isdst: true,
        text: '(UTC -04:00)',
      },
      {
        country: 'Atlantic',
        city: 'Stanley',
        abbr: 'SEST',
        offset: -3,
        isdst: false,
        text: '(UTC -03:00)',
      },
      {
        country: 'Atlantic',
        city: 'South_Georgia',
        abbr: 'U',
        offset: -2,
        isdst: false,
        text: '(UTC -02:00)',
      },
      {
        country: 'Atlantic',
        city: 'Azores',
        abbr: 'ADT',
        offset: 0,
        isdst: true,
        text: '(UTC -01:00)',
      },
      {
        country: 'Atlantic',
        city: 'Cape_Verde',
        abbr: 'CVST',
        offset: -1,
        isdst: false,
        text: '(UTC -01:00)',
      },
      {
        country: 'Atlantic',
        city: 'Canary',
        abbr: 'GDT',
        offset: 1,
        isdst: true,
        text: '(UTC 00:00)',
      },
      {
        country: 'Atlantic',
        city: 'Faeroe',
        abbr: 'GDT',
        offset: 1,
        isdst: true,
        text: '(UTC 00:00)',
      },
      {
        country: 'Atlantic',
        city: 'Madeira',
        abbr: 'GDT',
        offset: 1,
        isdst: true,
        text: '(UTC 00:00)',
      },
      {
        country: 'Atlantic',
        city: 'Reykjavik',
        abbr: 'GST',
        offset: 0,
        isdst: false,
        text: '(UTC 00:00)',
      },
      {
        country: 'Atlantic',
        city: 'St_Helena',
        abbr: 'GST',
        offset: 0,
        isdst: false,
        text: '(UTC 00:00)',
      },
    ],
  },
  {
    country: 'Antarctica',
    timezones: [
      {
        country: 'Antarctica',
        city: 'Palmer',
        abbr: 'PSST',
        offset: -4,
        isdst: false,
        text: '(UTC -04:00)',
      },
      {
        country: 'Antarctica',
        city: 'Rothera',
        abbr: 'SEST',
        offset: -3,
        isdst: false,
        text: '(UTC -03:00)',
      },
      {
        country: 'Antarctica',
        city: 'Syowa',
        abbr: 'EAST',
        offset: 3,
        isdst: false,
        text: '(UTC +03:00)',
      },
      {
        country: 'Antarctica',
        city: 'Mawson',
        abbr: 'WAST',
        offset: 5,
        isdst: false,
        text: '(UTC +05:00)',
      },
      {
        country: 'Antarctica',
        city: 'Vostok',
        abbr: 'CAST',
        offset: 6,
        isdst: false,
        text: '(UTC +06:00)',
      },
      {
        country: 'Antarctica',
        city: 'Davis',
        abbr: 'SAST',
        offset: 7,
        isdst: false,
        text: '(UTC +07:00)',
      },
      {
        country: 'Antarctica',
        city: 'Casey',
        abbr: 'WAST',
        offset: 8,
        isdst: false,
        text: '(UTC +08:00)',
      },
      {
        country: 'Antarctica',
        city: 'DumontDUrville',
        abbr: 'WPST',
        offset: 10,
        isdst: false,
        text: '(UTC +10:00)',
      },
      {
        country: 'Antarctica',
        city: 'Macquarie',
        abbr: 'CPST',
        offset: 11,
        isdst: false,
        text: '(UTC +11:00)',
      },
      {
        country: 'Antarctica',
        city: 'McMurdo',
        abbr: 'NZST',
        offset: 12,
        isdst: false,
        text: '(UTC +12:00)',
      },
    ],
  },
  {
    country: 'Africa',
    timezones: [
      {
        country: 'Africa',
        city: 'Casablanca',
        abbr: 'MDT',
        offset: 1,
        isdst: true,
        text: '(UTC 00:00)',
      },
      {
        country: 'Africa',
        city: 'El_Aaiun',
        abbr: 'MDT',
        offset: 1,
        isdst: true,
        text: '(UTC 00:00)',
      },
      {
        country: 'Africa',
        city: 'Abidjan',
        abbr: 'GST',
        offset: 0,
        isdst: false,
        text: '(UTC 00:00)',
      },
      {
        country: 'Africa',
        city: 'Accra',
        abbr: 'GST',
        offset: 0,
        isdst: false,
        text: '(UTC 00:00)',
      },
      {
        country: 'Africa',
        city: 'Bamako',
        abbr: 'GST',
        offset: 0,
        isdst: false,
        text: '(UTC 00:00)',
      },
      {
        country: 'Africa',
        city: 'Banjul',
        abbr: 'GST',
        offset: 0,
        isdst: false,
        text: '(UTC 00:00)',
      },
      {
        country: 'Africa',
        city: 'Bissau',
        abbr: 'GST',
        offset: 0,
        isdst: false,
        text: '(UTC 00:00)',
      },
      {
        country: 'Africa',
        city: 'Conakry',
        abbr: 'GST',
        offset: 0,
        isdst: false,
        text: '(UTC 00:00)',
      },
      {
        country: 'Africa',
        city: 'Dakar',
        abbr: 'GST',
        offset: 0,
        isdst: false,
        text: '(UTC 00:00)',
      },
      {
        country: 'Africa',
        city: 'Freetown',
        abbr: 'GST',
        offset: 0,
        isdst: false,
        text: '(UTC 00:00)',
      },
      {
        country: 'Africa',
        city: 'Lome',
        abbr: 'GST',
        offset: 0,
        isdst: false,
        text: '(UTC 00:00)',
      },
      {
        country: 'Africa',
        city: 'Monrovia',
        abbr: 'GST',
        offset: 0,
        isdst: false,
        text: '(UTC 00:00)',
      },
      {
        country: 'Africa',
        city: 'Nouakchott',
        abbr: 'GST',
        offset: 0,
        isdst: false,
        text: '(UTC 00:00)',
      },
      {
        country: 'Africa',
        city: 'Ouagadougou',
        abbr: 'GST',
        offset: 0,
        isdst: false,
        text: '(UTC 00:00)',
      },
      {
        country: 'Africa',
        city: 'Sao_Tome',
        abbr: 'GST',
        offset: 0,
        isdst: false,
        text: '(UTC 00:00)',
      },
      {
        country: 'Africa',
        city: 'Ceuta',
        abbr: 'RDT',
        offset: 2,
        isdst: true,
        text: '(UTC +01:00)',
      },
      {
        country: 'Africa',
        city: 'Algiers',
        abbr: 'WCAST',
        offset: 1,
        isdst: false,
        text: '(UTC +01:00)',
      },
      {
        country: 'Africa',
        city: 'Bangui',
        abbr: 'WCAST',
        offset: 1,
        isdst: false,
        text: '(UTC +01:00)',
      },
      {
        country: 'Africa',
        city: 'Brazzaville',
        abbr: 'WCAST',
        offset: 1,
        isdst: false,
        text: '(UTC +01:00)',
      },
      {
        country: 'Africa',
        city: 'Douala',
        abbr: 'WCAST',
        offset: 1,
        isdst: false,
        text: '(UTC +01:00)',
      },
      {
        country: 'Africa',
        city: 'Kinshasa',
        abbr: 'WCAST',
        offset: 1,
        isdst: false,
        text: '(UTC +01:00)',
      },
      {
        country: 'Africa',
        city: 'Lagos',
        abbr: 'WCAST',
        offset: 1,
        isdst: false,
        text: '(UTC +01:00)',
      },
      {
        country: 'Africa',
        city: 'Libreville',
        abbr: 'WCAST',
        offset: 1,
        isdst: false,
        text: '(UTC +01:00)',
      },
      {
        country: 'Africa',
        city: 'Luanda',
        abbr: 'WCAST',
        offset: 1,
        isdst: false,
        text: '(UTC +01:00)',
      },
      {
        country: 'Africa',
        city: 'Malabo',
        abbr: 'WCAST',
        offset: 1,
        isdst: false,
        text: '(UTC +01:00)',
      },
      {
        country: 'Africa',
        city: 'Ndjamena',
        abbr: 'WCAST',
        offset: 1,
        isdst: false,
        text: '(UTC +01:00)',
      },
      {
        country: 'Africa',
        city: 'Niamey',
        abbr: 'WCAST',
        offset: 1,
        isdst: false,
        text: '(UTC +01:00)',
      },
      {
        country: 'Africa',
        city: 'Porto-Novo',
        abbr: 'WCAST',
        offset: 1,
        isdst: false,
        text: '(UTC +01:00)',
      },
      {
        country: 'Africa',
        city: 'Tunis',
        abbr: 'WCAST',
        offset: 1,
        isdst: false,
        text: '(UTC +01:00)',
      },
      {
        country: 'Africa',
        city: 'Windhoek',
        abbr: 'NST',
        offset: 1,
        isdst: false,
        text: '(UTC +01:00)',
      },
      {
        country: 'Africa',
        city: 'Cairo',
        abbr: 'EST',
        offset: 2,
        isdst: false,
        text: '(UTC +02:00)',
      },
      {
        country: 'Africa',
        city: 'Blantyre',
        abbr: 'SAST',
        offset: 2,
        isdst: false,
        text: '(UTC +02:00)',
      },
      {
        country: 'Africa',
        city: 'Bujumbura',
        abbr: 'SAST',
        offset: 2,
        isdst: false,
        text: '(UTC +02:00)',
      },
      {
        country: 'Africa',
        city: 'Gaborone',
        abbr: 'SAST',
        offset: 2,
        isdst: false,
        text: '(UTC +02:00)',
      },
      {
        country: 'Africa',
        city: 'Harare',
        abbr: 'SAST',
        offset: 2,
        isdst: false,
        text: '(UTC +02:00)',
      },
      {
        country: 'Africa',
        city: 'Johannesburg',
        abbr: 'SAST',
        offset: 2,
        isdst: false,
        text: '(UTC +02:00)',
      },
      {
        country: 'Africa',
        city: 'Kigali',
        abbr: 'SAST',
        offset: 2,
        isdst: false,
        text: '(UTC +02:00)',
      },
      {
        country: 'Africa',
        city: 'Lubumbashi',
        abbr: 'SAST',
        offset: 2,
        isdst: false,
        text: '(UTC +02:00)',
      },
      {
        country: 'Africa',
        city: 'Lusaka',
        abbr: 'SAST',
        offset: 2,
        isdst: false,
        text: '(UTC +02:00)',
      },
      {
        country: 'Africa',
        city: 'Maputo',
        abbr: 'SAST',
        offset: 2,
        isdst: false,
        text: '(UTC +02:00)',
      },
      {
        country: 'Africa',
        city: 'Maseru',
        abbr: 'SAST',
        offset: 2,
        isdst: false,
        text: '(UTC +02:00)',
      },
      {
        country: 'Africa',
        city: 'Mbabane',
        abbr: 'SAST',
        offset: 2,
        isdst: false,
        text: '(UTC +02:00)',
      },
      {
        country: 'Africa',
        city: 'Tripoli',
        abbr: 'LST',
        offset: 2,
        isdst: false,
        text: '(UTC +02:00)',
      },
      {
        country: 'Africa',
        city: 'Addis_Ababa',
        abbr: 'EAST',
        offset: 3,
        isdst: false,
        text: '(UTC +03:00)',
      },
      {
        country: 'Africa',
        city: 'Asmera',
        abbr: 'EAST',
        offset: 3,
        isdst: false,
        text: '(UTC +03:00)',
      },
      {
        country: 'Africa',
        city: 'Dar_es_Salaam',
        abbr: 'EAST',
        offset: 3,
        isdst: false,
        text: '(UTC +03:00)',
      },
      {
        country: 'Africa',
        city: 'Djibouti',
        abbr: 'EAST',
        offset: 3,
        isdst: false,
        text: '(UTC +03:00)',
      },
      {
        country: 'Africa',
        city: 'Juba',
        abbr: 'EAST',
        offset: 3,
        isdst: false,
        text: '(UTC +03:00)',
      },
      {
        country: 'Africa',
        city: 'Kampala',
        abbr: 'EAST',
        offset: 3,
        isdst: false,
        text: '(UTC +03:00)',
      },
      {
        country: 'Africa',
        city: 'Khartoum',
        abbr: 'EAST',
        offset: 3,
        isdst: false,
        text: '(UTC +03:00)',
      },
      {
        country: 'Africa',
        city: 'Mogadishu',
        abbr: 'EAST',
        offset: 3,
        isdst: false,
        text: '(UTC +03:00)',
      },
      {
        country: 'Africa',
        city: 'Nairobi',
        abbr: 'EAST',
        offset: 3,
        isdst: false,
        text: '(UTC +03:00)',
      },
    ],
  },
  {
    country: 'Europe',
    timezones: [
      {
        country: 'Europe',
        city: 'Isle_of_Man',
        abbr: 'GMT',
        offset: 0,
        isdst: false,
        text: '(UTC 00:00)',
      },
      {
        country: 'Europe',
        city: 'Guernsey',
        abbr: 'GMT',
        offset: 0,
        isdst: false,
        text: '(UTC 00:00)',
      },
      {
        country: 'Europe',
        city: 'Jersey',
        abbr: 'GMT',
        offset: 0,
        isdst: false,
        text: '(UTC 00:00)',
      },
      {
        country: 'Europe',
        city: 'London',
        abbr: 'GMT',
        offset: 0,
        isdst: false,
        text: '(UTC 00:00)',
      },
      {
        country: 'Europe',
        city: 'Isle_of_Man',
        abbr: 'BST',
        offset: 1,
        isdst: true,
        text: '(UTC +01:00)',
      },
      {
        country: 'Europe',
        city: 'Guernsey',
        abbr: 'BST',
        offset: 1,
        isdst: true,
        text: '(UTC +01:00)',
      },
      {
        country: 'Europe',
        city: 'Jersey',
        abbr: 'BST',
        offset: 1,
        isdst: true,
        text: '(UTC +01:00)',
      },
      {
        country: 'Europe',
        city: 'London',
        abbr: 'BST',
        offset: 1,
        isdst: true,
        text: '(UTC +01:00)',
      },
      {
        country: 'Europe',
        city: 'Dublin',
        abbr: 'GDT',
        offset: 1,
        isdst: true,
        text: '(UTC 00:00)',
      },
      {
        country: 'Europe',
        city: 'Lisbon',
        abbr: 'GDT',
        offset: 1,
        isdst: true,
        text: '(UTC 00:00)',
      },
      {
        country: 'Europe',
        city: 'Amsterdam',
        abbr: 'WEDT',
        offset: 2,
        isdst: true,
        text: '(UTC +01:00)',
      },
      {
        country: 'Europe',
        city: 'Andorra',
        abbr: 'WEDT',
        offset: 2,
        isdst: true,
        text: '(UTC +01:00)',
      },
      {
        country: 'Europe',
        city: 'Berlin',
        abbr: 'WEDT',
        offset: 2,
        isdst: true,
        text: '(UTC +01:00)',
      },
      {
        country: 'Europe',
        city: 'Busingen',
        abbr: 'WEDT',
        offset: 2,
        isdst: true,
        text: '(UTC +01:00)',
      },
      {
        country: 'Europe',
        city: 'Gibraltar',
        abbr: 'WEDT',
        offset: 2,
        isdst: true,
        text: '(UTC +01:00)',
      },
      {
        country: 'Europe',
        city: 'Luxembourg',
        abbr: 'WEDT',
        offset: 2,
        isdst: true,
        text: '(UTC +01:00)',
      },
      {
        country: 'Europe',
        city: 'Malta',
        abbr: 'WEDT',
        offset: 2,
        isdst: true,
        text: '(UTC +01:00)',
      },
      {
        country: 'Europe',
        city: 'Monaco',
        abbr: 'WEDT',
        offset: 2,
        isdst: true,
        text: '(UTC +01:00)',
      },
      {
        country: 'Europe',
        city: 'Oslo',
        abbr: 'WEDT',
        offset: 2,
        isdst: true,
        text: '(UTC +01:00)',
      },
      {
        country: 'Europe',
        city: 'Rome',
        abbr: 'WEDT',
        offset: 2,
        isdst: true,
        text: '(UTC +01:00)',
      },
      {
        country: 'Europe',
        city: 'San_Marino',
        abbr: 'WEDT',
        offset: 2,
        isdst: true,
        text: '(UTC +01:00)',
      },
      {
        country: 'Europe',
        city: 'Stockholm',
        abbr: 'WEDT',
        offset: 2,
        isdst: true,
        text: '(UTC +01:00)',
      },
      {
        country: 'Europe',
        city: 'Vaduz',
        abbr: 'WEDT',
        offset: 2,
        isdst: true,
        text: '(UTC +01:00)',
      },
      {
        country: 'Europe',
        city: 'Vatican',
        abbr: 'WEDT',
        offset: 2,
        isdst: true,
        text: '(UTC +01:00)',
      },
      {
        country: 'Europe',
        city: 'Vienna',
        abbr: 'WEDT',
        offset: 2,
        isdst: true,
        text: '(UTC +01:00)',
      },
      {
        country: 'Europe',
        city: 'Zurich',
        abbr: 'WEDT',
        offset: 2,
        isdst: true,
        text: '(UTC +01:00)',
      },
      {
        country: 'Europe',
        city: 'Belgrade',
        abbr: 'CEDT',
        offset: 2,
        isdst: true,
        text: '(UTC +01:00)',
      },
      {
        country: 'Europe',
        city: 'Bratislava',
        abbr: 'CEDT',
        offset: 2,
        isdst: true,
        text: '(UTC +01:00)',
      },
      {
        country: 'Europe',
        city: 'Budapest',
        abbr: 'CEDT',
        offset: 2,
        isdst: true,
        text: '(UTC +01:00)',
      },
      {
        country: 'Europe',
        city: 'Ljubljana',
        abbr: 'CEDT',
        offset: 2,
        isdst: true,
        text: '(UTC +01:00)',
      },
      {
        country: 'Europe',
        city: 'Podgorica',
        abbr: 'CEDT',
        offset: 2,
        isdst: true,
        text: '(UTC +01:00)',
      },
      {
        country: 'Europe',
        city: 'Prague',
        abbr: 'CEDT',
        offset: 2,
        isdst: true,
        text: '(UTC +01:00)',
      },
      {
        country: 'Europe',
        city: 'Tirane',
        abbr: 'CEDT',
        offset: 2,
        isdst: true,
        text: '(UTC +01:00)',
      },
      {
        country: 'Europe',
        city: 'Brussels',
        abbr: 'RDT',
        offset: 2,
        isdst: true,
        text: '(UTC +01:00)',
      },
      {
        country: 'Europe',
        city: 'Copenhagen',
        abbr: 'RDT',
        offset: 2,
        isdst: true,
        text: '(UTC +01:00)',
      },
      {
        country: 'Europe',
        city: 'Madrid',
        abbr: 'RDT',
        offset: 2,
        isdst: true,
        text: '(UTC +01:00)',
      },
      {
        country: 'Europe',
        city: 'Paris',
        abbr: 'RDT',
        offset: 2,
        isdst: true,
        text: '(UTC +01:00)',
      },
      {
        country: 'Europe',
        city: 'Sarajevo',
        abbr: 'CEDT',
        offset: 2,
        isdst: true,
        text: '(UTC +01:00)',
      },
      {
        country: 'Europe',
        city: 'Skopje',
        abbr: 'CEDT',
        offset: 2,
        isdst: true,
        text: '(UTC +01:00)',
      },
      {
        country: 'Europe',
        city: 'Warsaw',
        abbr: 'CEDT',
        offset: 2,
        isdst: true,
        text: '(UTC +01:00)',
      },
      {
        country: 'Europe',
        city: 'Zagreb',
        abbr: 'CEDT',
        offset: 2,
        isdst: true,
        text: '(UTC +01:00)',
      },
      {
        country: 'Europe',
        city: 'Athens',
        abbr: 'GDT',
        offset: 3,
        isdst: true,
        text: '(UTC +02:00)',
      },
      {
        country: 'Europe',
        city: 'Bucharest',
        abbr: 'GDT',
        offset: 3,
        isdst: true,
        text: '(UTC +02:00)',
      },
      {
        country: 'Europe',
        city: 'Chisinau',
        abbr: 'GDT',
        offset: 3,
        isdst: true,
        text: '(UTC +02:00)',
      },
      {
        country: 'Europe',
        city: 'Athens',
        abbr: 'EEDT',
        offset: 3,
        isdst: true,
        text: '(UTC +02:00)',
      },
      {
        country: 'Europe',
        city: 'Bucharest',
        abbr: 'EEDT',
        offset: 3,
        isdst: true,
        text: '(UTC +02:00)',
      },
      {
        country: 'Europe',
        city: 'Chisinau',
        abbr: 'EEDT',
        offset: 3,
        isdst: true,
        text: '(UTC +02:00)',
      },
      {
        country: 'Europe',
        city: 'Helsinki',
        abbr: 'EEDT',
        offset: 3,
        isdst: true,
        text: '(UTC +02:00)',
      },
      {
        country: 'Europe',
        city: 'Kyiv',
        abbr: 'EEDT',
        offset: 3,
        isdst: true,
        text: '(UTC +02:00)',
      },
      {
        country: 'Europe',
        city: 'Mariehamn',
        abbr: 'EEDT',
        offset: 3,
        isdst: true,
        text: '(UTC +02:00)',
      },
      {
        country: 'Europe',
        city: 'Nicosia',
        abbr: 'EEDT',
        offset: 3,
        isdst: true,
        text: '(UTC +02:00)',
      },
      {
        country: 'Europe',
        city: 'Riga',
        abbr: 'EEDT',
        offset: 3,
        isdst: true,
        text: '(UTC +02:00)',
      },
      {
        country: 'Europe',
        city: 'Sofia',
        abbr: 'EEDT',
        offset: 3,
        isdst: true,
        text: '(UTC +02:00)',
      },
      {
        country: 'Europe',
        city: 'Tallinn',
        abbr: 'EEDT',
        offset: 3,
        isdst: true,
        text: '(UTC +02:00)',
      },
      {
        country: 'Europe',
        city: 'Uzhgorod',
        abbr: 'EEDT',
        offset: 3,
        isdst: true,
        text: '(UTC +02:00)',
      },
      {
        country: 'Europe',
        city: 'Vilnius',
        abbr: 'EEDT',
        offset: 3,
        isdst: true,
        text: '(UTC +02:00)',
      },
      {
        country: 'Europe',
        city: 'Zaporozhye',
        abbr: 'EEDT',
        offset: 3,
        isdst: true,
        text: '(UTC +02:00)',
      },
      {
        country: 'Europe',
        city: 'Helsinki',
        abbr: 'FDT',
        offset: 3,
        isdst: true,
        text: '(UTC +02:00)',
      },
      {
        country: 'Europe',
        city: 'Kyiv',
        abbr: 'FDT',
        offset: 3,
        isdst: true,
        text: '(UTC +02:00)',
      },
      {
        country: 'Europe',
        city: 'Mariehamn',
        abbr: 'FDT',
        offset: 3,
        isdst: true,
        text: '(UTC +02:00)',
      },
      {
        country: 'Europe',
        city: 'Riga',
        abbr: 'FDT',
        offset: 3,
        isdst: true,
        text: '(UTC +02:00)',
      },
      {
        country: 'Europe',
        city: 'Sofia',
        abbr: 'FDT',
        offset: 3,
        isdst: true,
        text: '(UTC +02:00)',
      },
      {
        country: 'Europe',
        city: 'Tallinn',
        abbr: 'FDT',
        offset: 3,
        isdst: true,
        text: '(UTC +02:00)',
      },
      {
        country: 'Europe',
        city: 'Uzhgorod',
        abbr: 'FDT',
        offset: 3,
        isdst: true,
        text: '(UTC +02:00)',
      },
      {
        country: 'Europe',
        city: 'Vilnius',
        abbr: 'FDT',
        offset: 3,
        isdst: true,
        text: '(UTC +02:00)',
      },
      {
        country: 'Europe',
        city: 'Zaporozhye',
        abbr: 'FDT',
        offset: 3,
        isdst: true,
        text: '(UTC +02:00)',
      },
      {
        country: 'Europe',
        city: 'Istanbul',
        abbr: 'TDT',
        offset: 3,
        isdst: false,
        text: '(UTC +03:00)',
      },
      {
        country: 'Europe',
        city: 'Kaliningrad',
        abbr: 'KST',
        offset: 3,
        isdst: false,
        text: '(UTC +02:00)',
      },
      {
        country: 'Europe',
        city: 'Kirov',
        abbr: 'MSK',
        offset: 3,
        isdst: false,
        text: '(UTC +03:00)',
      },
      {
        country: 'Europe',
        city: 'Moscow',
        abbr: 'MSK',
        offset: 3,
        isdst: false,
        text: '(UTC +03:00)',
      },
      {
        country: 'Europe',
        city: 'Simferopol',
        abbr: 'MSK',
        offset: 3,
        isdst: false,
        text: '(UTC +03:00)',
      },
      {
        country: 'Europe',
        city: 'Volgograd',
        abbr: 'MSK',
        offset: 3,
        isdst: false,
        text: '(UTC +03:00)',
      },
      {
        country: 'Europe',
        city: 'Minsk',
        abbr: 'MSK',
        offset: 3,
        isdst: false,
        text: '(UTC +03:00)',
      },
      {
        country: 'Europe',
        city: 'Astrakhan',
        abbr: 'SAMT',
        offset: 4,
        isdst: false,
        text: '(UTC +04:00)',
      },
      {
        country: 'Europe',
        city: 'Samara',
        abbr: 'SAMT',
        offset: 4,
        isdst: false,
        text: '(UTC +04:00)',
      },
      {
        country: 'Europe',
        city: 'Ulyanovsk',
        abbr: 'SAMT',
        offset: 4,
        isdst: false,
        text: '(UTC +04:00)',
      },
    ],
  },
  {
    country: 'Arctic',
    timezones: [
      {
        country: 'Arctic',
        city: 'Longyearbyen',
        abbr: 'WEDT',
        offset: 2,
        isdst: true,
        text: '(UTC +01:00)',
      },
    ],
  },
  {
    country: 'Asia',
    timezones: [
      {
        country: 'Asia',
        city: 'Nicosia',
        abbr: 'GDT',
        offset: 3,
        isdst: true,
        text: '(UTC +02:00)',
      },
      {
        country: 'Asia',
        city: 'Beirut',
        abbr: 'MEDT',
        offset: 3,
        isdst: true,
        text: '(UTC +02:00)',
      },
      {
        country: 'Asia',
        city: 'Damascus',
        abbr: 'SDT',
        offset: 3,
        isdst: true,
        text: '(UTC +02:00)',
      },
      {
        country: 'Asia',
        city: 'Nicosia',
        abbr: 'EEDT',
        offset: 3,
        isdst: true,
        text: '(UTC +02:00)',
      },
      {
        country: 'Asia',
        city: 'Jerusalem',
        abbr: 'JDT',
        offset: 3,
        isdst: true,
        text: '(UTC +02:00)',
      },
      {
        country: 'Asia',
        city: 'Amman',
        abbr: 'JST',
        offset: 3,
        isdst: false,
        text: '(UTC +03:00)',
      },
      {
        country: 'Asia',
        city: 'Baghdad',
        abbr: 'AST',
        offset: 3,
        isdst: false,
        text: '(UTC +03:00)',
      },
      {
        country: 'Asia',
        city: 'Aden',
        abbr: 'AST',
        offset: 3,
        isdst: false,
        text: '(UTC +03:00)',
      },
      {
        country: 'Asia',
        city: 'Bahrain',
        abbr: 'AST',
        offset: 3,
        isdst: false,
        text: '(UTC +03:00)',
      },
      {
        country: 'Asia',
        city: 'Kuwait',
        abbr: 'AST',
        offset: 3,
        isdst: false,
        text: '(UTC +03:00)',
      },
      {
        country: 'Asia',
        city: 'Qatar',
        abbr: 'AST',
        offset: 3,
        isdst: false,
        text: '(UTC +03:00)',
      },
      {
        country: 'Asia',
        city: 'Riyadh',
        abbr: 'AST',
        offset: 3,
        isdst: false,
        text: '(UTC +03:00)',
      },
      {
        country: 'Asia',
        city: 'Tehran',
        abbr: 'IDT',
        offset: 4.5,
        isdst: true,
        text: '(UTC +03:30)',
      },
      {
        country: 'Asia',
        city: 'Dubai',
        abbr: 'AST',
        offset: 4,
        isdst: false,
        text: '(UTC +04:00)',
      },
      {
        country: 'Asia',
        city: 'Muscat',
        abbr: 'AST',
        offset: 4,
        isdst: false,
        text: '(UTC +04:00)',
      },
      {
        country: 'Asia',
        city: 'Baku',
        abbr: 'ADT',
        offset: 5,
        isdst: true,
        text: '(UTC +04:00)',
      },
      {
        country: 'Asia',
        city: 'Tbilisi',
        abbr: 'GET',
        offset: 4,
        isdst: false,
        text: '(UTC +04:00)',
      },
      {
        country: 'Asia',
        city: 'Yerevan',
        abbr: 'CST',
        offset: 4,
        isdst: false,
        text: '(UTC +04:00)',
      },
      {
        country: 'Asia',
        city: 'Kabul',
        abbr: 'AST',
        offset: 4.5,
        isdst: false,
        text: '(UTC +04:30)',
      },
      {
        country: 'Asia',
        city: 'Aqtau',
        abbr: 'WAST',
        offset: 5,
        isdst: false,
        text: '(UTC +05:00)',
      },
      {
        country: 'Asia',
        city: 'Aqtobe',
        abbr: 'WAST',
        offset: 5,
        isdst: false,
        text: '(UTC +05:00)',
      },
      {
        country: 'Asia',
        city: 'Ashgabat',
        abbr: 'WAST',
        offset: 5,
        isdst: false,
        text: '(UTC +05:00)',
      },
      {
        country: 'Asia',
        city: 'Dushanbe',
        abbr: 'WAST',
        offset: 5,
        isdst: false,
        text: '(UTC +05:00)',
      },
      {
        country: 'Asia',
        city: 'Oral',
        abbr: 'WAST',
        offset: 5,
        isdst: false,
        text: '(UTC +05:00)',
      },
      {
        country: 'Asia',
        city: 'Samarkand',
        abbr: 'WAST',
        offset: 5,
        isdst: false,
        text: '(UTC +05:00)',
      },
      {
        country: 'Asia',
        city: 'Tashkent',
        abbr: 'WAST',
        offset: 5,
        isdst: false,
        text: '(UTC +05:00)',
      },
      {
        country: 'Asia',
        city: 'Yekaterinburg',
        abbr: 'YEKT',
        offset: 5,
        isdst: false,
        text: '(UTC +05:00)',
      },
      {
        country: 'Asia',
        city: 'Karachi',
        abbr: 'PKT',
        offset: 5,
        isdst: false,
        text: '(UTC +05:00)',
      },
      {
        country: 'Asia',
        city: 'Kolkata',
        abbr: 'IST',
        offset: 5.5,
        isdst: false,
        text: '(UTC +05:30)',
      },
      {
        country: 'Asia',
        city: 'Calcutta',
        abbr: 'IST',
        offset: 5.5,
        isdst: false,
        text: '(UTC +05:30)',
      },
      {
        country: 'Asia',
        city: 'Colombo',
        abbr: 'SLST',
        offset: 5.5,
        isdst: false,
        text: '(UTC +05:30)',
      },
      {
        country: 'Asia',
        city: 'Kathmandu',
        abbr: 'NST',
        offset: 5.75,
        isdst: false,
        text: '(UTC +05:45)',
      },
      {
        country: 'Asia',
        city: 'Almaty',
        abbr: 'CAST',
        offset: 6,
        isdst: false,
        text: '(UTC +06:00)',
      },
      {
        country: 'Asia',
        city: 'Bishkek',
        abbr: 'CAST',
        offset: 6,
        isdst: false,
        text: '(UTC +06:00)',
      },
      {
        country: 'Asia',
        city: 'Qyzylorda',
        abbr: 'CAST',
        offset: 6,
        isdst: false,
        text: '(UTC +06:00)',
      },
      {
        country: 'Asia',
        city: 'Urumqi',
        abbr: 'CAST',
        offset: 6,
        isdst: false,
        text: '(UTC +06:00)',
      },
      {
        country: 'Asia',
        city: 'Dhaka',
        abbr: 'BST',
        offset: 6,
        isdst: false,
        text: '(UTC +06:00)',
      },
      {
        country: 'Asia',
        city: 'Thimphu',
        abbr: 'BST',
        offset: 6,
        isdst: false,
        text: '(UTC +06:00)',
      },
      {
        country: 'Asia',
        city: 'Rangoon',
        abbr: 'MST',
        offset: 6.5,
        isdst: false,
        text: '(UTC +06:30)',
      },
      {
        country: 'Asia',
        city: 'Bangkok',
        abbr: 'SAST',
        offset: 7,
        isdst: false,
        text: '(UTC +07:00)',
      },
      {
        country: 'Asia',
        city: 'Hovd',
        abbr: 'SAST',
        offset: 7,
        isdst: false,
        text: '(UTC +07:00)',
      },
      {
        country: 'Asia',
        city: 'Jakarta',
        abbr: 'SAST',
        offset: 7,
        isdst: false,
        text: '(UTC +07:00)',
      },
      {
        country: 'Asia',
        city: 'Phnom_Penh',
        abbr: 'SAST',
        offset: 7,
        isdst: false,
        text: '(UTC +07:00)',
      },
      {
        country: 'Asia',
        city: 'Pontianak',
        abbr: 'SAST',
        offset: 7,
        isdst: false,
        text: '(UTC +07:00)',
      },
      {
        country: 'Asia',
        city: 'Saigon',
        abbr: 'SAST',
        offset: 7,
        isdst: false,
        text: '(UTC +07:00)',
      },
      {
        country: 'Asia',
        city: 'Vientiane',
        abbr: 'SAST',
        offset: 7,
        isdst: false,
        text: '(UTC +07:00)',
      },
      {
        country: 'Asia',
        city: 'Novokuznetsk',
        abbr: 'NCAST',
        offset: 7,
        isdst: false,
        text: '(UTC +07:00)',
      },
      {
        country: 'Asia',
        city: 'Novosibirsk',
        abbr: 'NCAST',
        offset: 7,
        isdst: false,
        text: '(UTC +07:00)',
      },
      {
        country: 'Asia',
        city: 'Omsk',
        abbr: 'NCAST',
        offset: 7,
        isdst: false,
        text: '(UTC +07:00)',
      },
      {
        country: 'Asia',
        city: 'Hong_Kong',
        abbr: 'CST',
        offset: 8,
        isdst: false,
        text: '(UTC +08:00)',
      },
      {
        country: 'Asia',
        city: 'Macau',
        abbr: 'CST',
        offset: 8,
        isdst: false,
        text: '(UTC +08:00)',
      },
      {
        country: 'Asia',
        city: 'Shanghai',
        abbr: 'CST',
        offset: 8,
        isdst: false,
        text: '(UTC +08:00)',
      },
      {
        country: 'Asia',
        city: 'Krasnoyarsk',
        abbr: 'NAST',
        offset: 8,
        isdst: false,
        text: '(UTC +08:00)',
      },
      {
        country: 'Asia',
        city: 'Brunei',
        abbr: 'MPST',
        offset: 8,
        isdst: false,
        text: '(UTC +08:00)',
      },
      {
        country: 'Asia',
        city: 'Kuala_Lumpur',
        abbr: 'MPST',
        offset: 8,
        isdst: false,
        text: '(UTC +08:00)',
      },
      {
        country: 'Asia',
        city: 'Kuching',
        abbr: 'MPST',
        offset: 8,
        isdst: false,
        text: '(UTC +08:00)',
      },
      {
        country: 'Asia',
        city: 'Makassar',
        abbr: 'MPST',
        offset: 8,
        isdst: false,
        text: '(UTC +08:00)',
      },
      {
        country: 'Asia',
        city: 'Manila',
        abbr: 'MPST',
        offset: 8,
        isdst: false,
        text: '(UTC +08:00)',
      },
      {
        country: 'Asia',
        city: 'Singapore',
        abbr: 'MPST',
        offset: 8,
        isdst: false,
        text: '(UTC +08:00)',
      },
      {
        country: 'Asia',
        city: 'Taipei',
        abbr: 'TST',
        offset: 8,
        isdst: false,
        text: '(UTC +08:00)',
      },
      {
        country: 'Asia',
        city: 'Choibalsan',
        abbr: 'UST',
        offset: 8,
        isdst: false,
        text: '(UTC +08:00)',
      },
      {
        country: 'Asia',
        city: 'Ulaanbaatar',
        abbr: 'UST',
        offset: 8,
        isdst: false,
        text: '(UTC +08:00)',
      },
      {
        country: 'Asia',
        city: 'Irkutsk',
        abbr: 'NAEST',
        offset: 8,
        isdst: false,
        text: '(UTC +08:00)',
      },
      {
        country: 'Asia',
        city: 'Dili',
        abbr: 'JST',
        offset: 9,
        isdst: false,
        text: '(UTC +09:00)',
      },
      {
        country: 'Asia',
        city: 'Jayapura',
        abbr: 'JST',
        offset: 9,
        isdst: false,
        text: '(UTC +09:00)',
      },
      {
        country: 'Asia',
        city: 'Tokyo',
        abbr: 'JST',
        offset: 9,
        isdst: false,
        text: '(UTC +09:00)',
      },
      {
        country: 'Asia',
        city: 'Pyongyang',
        abbr: 'KST',
        offset: 9,
        isdst: false,
        text: '(UTC +09:00)',
      },
      {
        country: 'Asia',
        city: 'Seoul',
        abbr: 'KST',
        offset: 9,
        isdst: false,
        text: '(UTC +09:00)',
      },
      {
        country: 'Asia',
        city: 'Chita',
        abbr: 'YST',
        offset: 9,
        isdst: false,
        text: '(UTC +09:00)',
      },
      {
        country: 'Asia',
        city: 'Khandyga',
        abbr: 'YST',
        offset: 9,
        isdst: false,
        text: '(UTC +09:00)',
      },
      {
        country: 'Asia',
        city: 'Yakutsk',
        abbr: 'YST',
        offset: 9,
        isdst: false,
        text: '(UTC +09:00)',
      },
      {
        country: 'Asia',
        city: 'Sakhalin',
        abbr: 'VST',
        offset: 11,
        isdst: false,
        text: '(UTC +11:00)',
      },
      {
        country: 'Asia',
        city: 'Ust -Nera',
        abbr: 'VST',
        offset: 11,
        isdst: false,
        text: '(UTC +11:00)',
      },
      {
        country: 'Asia',
        city: 'Vladivostok',
        abbr: 'VST',
        offset: 11,
        isdst: false,
        text: '(UTC +11:00)',
      },
      {
        country: 'Asia',
        city: 'Anadyr',
        abbr: 'MST',
        offset: 12,
        isdst: false,
        text: '(UTC +12:00)',
      },
      {
        country: 'Asia',
        city: 'Kamchatka',
        abbr: 'MST',
        offset: 12,
        isdst: false,
        text: '(UTC +12:00)',
      },
      {
        country: 'Asia',
        city: 'Magadan',
        abbr: 'MST',
        offset: 12,
        isdst: false,
        text: '(UTC +12:00)',
      },
      {
        country: 'Asia',
        city: 'Srednekolymsk',
        abbr: 'MST',
        offset: 12,
        isdst: false,
        text: '(UTC +12:00)',
      },
      {
        country: 'Asia',
        city: 'Kamchatka',
        abbr: 'KDT',
        offset: 13,
        isdst: true,
        text: '(UTC +12:00)',
      },
    ],
  },
  {
    country: 'Indian',
    timezones: [
      {
        country: 'Indian',
        city: 'Antananarivo',
        abbr: 'EAST',
        offset: 3,
        isdst: false,
        text: '(UTC +03:00)',
      },
      {
        country: 'Indian',
        city: 'Comoro',
        abbr: 'EAST',
        offset: 3,
        isdst: false,
        text: '(UTC +03:00)',
      },
      {
        country: 'Indian',
        city: 'Mayotte',
        abbr: 'EAST',
        offset: 3,
        isdst: false,
        text: '(UTC +03:00)',
      },
      {
        country: 'Indian',
        city: 'Mahe',
        abbr: 'MST',
        offset: 4,
        isdst: false,
        text: '(UTC +04:00)',
      },
      {
        country: 'Indian',
        city: 'Mauritius',
        abbr: 'MST',
        offset: 4,
        isdst: false,
        text: '(UTC +04:00)',
      },
      {
        country: 'Indian',
        city: 'Reunion',
        abbr: 'MST',
        offset: 4,
        isdst: false,
        text: '(UTC +04:00)',
      },
      {
        country: 'Indian',
        city: 'Kerguelen',
        abbr: 'WAST',
        offset: 5,
        isdst: false,
        text: '(UTC +05:00)',
      },
      {
        country: 'Indian',
        city: 'Maldives',
        abbr: 'WAST',
        offset: 5,
        isdst: false,
        text: '(UTC +05:00)',
      },
      {
        country: 'Indian',
        city: 'Chagos',
        abbr: 'CAST',
        offset: 6,
        isdst: false,
        text: '(UTC +06:00)',
      },
      {
        country: 'Indian',
        city: 'Cocos',
        abbr: 'MST',
        offset: 6.5,
        isdst: false,
        text: '(UTC +06:30)',
      },
      {
        country: 'Indian',
        city: 'Christmas',
        abbr: 'SAST',
        offset: 7,
        isdst: false,
        text: '(UTC +07:00)',
      },
    ],
  },
  {
    country: 'Etc',
    timezones: [
      {
        country: 'Etc',
        city: 'GMT +12',
        abbr: 'DST',
        offset: -12,
        isdst: false,
        text: '(UTC -12:00)',
      },
      {
        country: 'Etc',
        city: 'GMT +11',
        abbr: 'U',
        offset: -11,
        isdst: false,
        text: '(UTC -11:00)',
      },
      {
        country: 'Etc',
        city: 'GMT +10',
        abbr: 'HST',
        offset: -10,
        isdst: false,
        text: '(UTC -10:00)',
      },
      {
        country: 'Etc',
        city: 'GMT +7',
        abbr: 'UMST',
        offset: -7,
        isdst: false,
        text: '(UTC -07:00)',
      },
      {
        country: 'Etc',
        city: 'GMT +6',
        abbr: 'CAST',
        offset: -6,
        isdst: false,
        text: '(UTC -06:00)',
      },
      {
        country: 'Etc',
        city: 'GMT +5',
        abbr: 'SPST',
        offset: -5,
        isdst: false,
        text: '(UTC -05:00)',
      },
      {
        country: 'Etc',
        city: 'GMT +4',
        abbr: 'SWST',
        offset: -4,
        isdst: false,
        text: '(UTC -04:00)',
      },
      {
        country: 'Etc',
        city: 'GMT +3',
        abbr: 'SEST',
        offset: -3,
        isdst: false,
        text: '(UTC -03:00)',
      },
      {
        country: 'Etc',
        city: 'GMT +2',
        abbr: 'U',
        offset: -2,
        isdst: false,
        text: '(UTC -02:00)',
      },
      {
        country: 'Etc',
        city: 'GMT +1',
        abbr: 'CVST',
        offset: -1,
        isdst: false,
        text: '(UTC -01:00)',
      },
      {
        country: 'Etc',
        city: 'GMT',
        abbr: 'UTC',
        offset: 0,
        isdst: false,
        text: '(UTC 00:00)',
      },
      {
        country: 'Etc',
        city: 'GMT -1',
        abbr: 'WCAST',
        offset: 1,
        isdst: false,
        text: '(UTC +01:00)',
      },
      {
        country: 'Etc',
        city: 'GMT -2',
        abbr: 'SAST',
        offset: 2,
        isdst: false,
        text: '(UTC +02:00)',
      },
      {
        country: 'Etc',
        city: 'GMT -3',
        abbr: 'EAST',
        offset: 3,
        isdst: false,
        text: '(UTC +03:00)',
      },
      {
        country: 'Etc',
        city: 'GMT -4',
        abbr: 'AST',
        offset: 4,
        isdst: false,
        text: '(UTC +04:00)',
      },
      {
        country: 'Etc',
        city: 'GMT -5',
        abbr: 'WAST',
        offset: 5,
        isdst: false,
        text: '(UTC +05:00)',
      },
      {
        country: 'Etc',
        city: 'GMT -6',
        abbr: 'CAST',
        offset: 6,
        isdst: false,
        text: '(UTC +06:00)',
      },
      {
        country: 'Etc',
        city: 'GMT -7',
        abbr: 'SAST',
        offset: 7,
        isdst: false,
        text: '(UTC +07:00)',
      },
      {
        country: 'Etc',
        city: 'GMT -8',
        abbr: 'MPST',
        offset: 8,
        isdst: false,
        text: '(UTC +08:00)',
      },
      {
        country: 'Etc',
        city: 'GMT -9',
        abbr: 'JST',
        offset: 9,
        isdst: false,
        text: '(UTC +09:00)',
      },
      {
        country: 'Etc',
        city: 'GMT -10',
        abbr: 'WPST',
        offset: 10,
        isdst: false,
        text: '(UTC +10:00)',
      },
      {
        country: 'Etc',
        city: 'GMT -11',
        abbr: 'CPST',
        offset: 11,
        isdst: false,
        text: '(UTC +11:00)',
      },
      {
        country: 'Etc',
        city: 'GMT -12',
        abbr: 'U',
        offset: 12,
        isdst: false,
        text: '(UTC +12:00)',
      },
      {
        country: 'Etc',
        city: 'GMT -13',
        abbr: 'TST',
        offset: 13,
        isdst: false,
        text: '(UTC +13:00)',
      },
    ],
  },
];
